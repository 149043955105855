<template>
  <div class="relative flex items-start">
    <div class="flex h-6 items-center">
      <input
        :id="uuid"
        aria-describedby=""
        type="checkbox"
        :class="{ checked: props.modelValue === true }"
        :value="props.modelValue"
        :checked="props.modelValue === true"
        :readonly="props.readonly"
        class="appearance-none checked:bg-primary w-4 h-4 rounded border-2 border-secondary focus:ring-2 focus:ring-primary-50"
        @input="onInputChange"
      />
    </div>
    <div class="ml-2.5 text-secondary leading-6">
      <template v-if="props.label">
        <template v-if="tooltipMsg">
          <TopbarTooltip :message="tooltipMsg">
            <label
              :for="uuid"
              class="text-sm font-semibold text-tertiary cursor-pointer"
              v-text="props.label"
            />
          </TopbarTooltip>
        </template>
        <template v-else>
          <label
            :for="uuid"
            class="text-sm font-semibold text-tertiary cursor-pointer"
            v-text="props.label"
          />
        </template>
      </template>

      <template v-if="props.helpText">
        <template v-if="tooltipMsg">
          <TopbarTooltip :message="tooltipMsg">
            <p class="text-xs text-secondary-80" v-text="props.helpText" />
          </TopbarTooltip>
        </template>
        <template v-else>
          <p class="text-xs text-secondary-80" v-text="props.helpText" />
        </template>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { UUID } from '@avvoka/shared'
import TopbarTooltip from '../../../topbar/components/TopbarTooltip.vue'

const props = withDefaults(
  defineProps<{
    label?: string
    modelValue: boolean
    helpText?: string
    readonly?: boolean
    margin?: boolean
    tooltipMsg?: string
  }>(),
  {
    margin: true
  }
)

const emit = defineEmits(['update:model-value'])
const onInputChange = () => {
  if (props.readonly) return
  emit('update:model-value', !props.modelValue)
}

const uuid = UUID.new()
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'DCheckbox' })
</script>
